import { Query } from 'react-apollo';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import categoryQuery from 'graphql/queries/checkCategory.graphql';
import staticPageQuery from 'graphql/queries/staticPage.graphql';
import LoadingPage from 'components/pages/LoadingPage';
import ErrorLoadingPage from 'components/pages/ErrorLoadingPage';
import NotFoundPage from 'components/pages/NotFoundPage';
import MainTemplate from 'components/templates/MainTemplate';
import TextFormatter from 'components/atoms/TextFormatter';
import Redirect from 'components/atoms/Redirect';

const Title = styled.h1`
  font-weight: normal;
`;

const StaticPage = ({ location }) => (
  <Query
    query={staticPageQuery}
    variables={{
      path: location.pathname,
    }}
  >
    {({ loading, data, error }) => {
      if (loading) {
        return <LoadingPage />;
      }

      if (error) {
        return <ErrorLoadingPage />;
      }

      if (!data.staticPage) {
        return <NotFoundPage />;
      }

      return (
        <MainTemplate>
          <Helmet>
            <title>{data.staticPage.title}</title>
            {data.staticPage.metaKeywords && (
              <meta name="keywords" content={data.staticPage.metaKeywords} />
            )}
            {data.staticPage.metaDescription && (
              <meta name="description" content={data.staticPage.metaDescription} />
            )}
          </Helmet>
          <Title>{data.staticPage.h1}</Title>
          <TextFormatter text={data.staticPage.content} />
        </MainTemplate>
      );
    }}
  </Query>
);

export default ({ location }) => {
  const translit = location.pathname.match(/^\/(.*?)(\/page([0-9]+))?$/)[1];

  return (
    <Query
      query={categoryQuery}
      variables={{
        translit,
      }}
    >
      {({ loading, data, error }) => {
        if (loading) {
          return <LoadingPage />;
        }

        if (error) {
          return <ErrorLoadingPage />;
        }

        if (!data.category) {
          return <StaticPage location={location} />;
        }

        return <Redirect to={`/catalog/${translit}`} />;
      }}
    </Query>
  );
};
